// Get existing cookies
var cookies = document.cookie.split(";");
cookies.forEach((el, i) => {
    var cookie = el.split("=");
    cookies[cookie[0].trim()] = cookie[1];
});

// Set body attribute if fonts have already been loaded
if (cookies["contrastMode"] == "on") {
    document.body.setAttribute("data-contrast-mode", "on");

    document
        .querySelectorAll("a.rd-button[data-contrast-mode='off']")
        .forEach((el) => {
            el.classList.remove("active");
        });

    document
        .querySelectorAll("a.rd-button[data-contrast-mode='on']")
        .forEach((el) => {
            el.classList.add("active");
        });
}
